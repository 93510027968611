/* ==========================================================================
    [2. RESET STYLES]
========================================================================== */

body {
    overflow-x: hidden;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
}

a {
    transition: all .3s ease-in;

    &, &:focus, &:active, &:hover {
        outline: 0 !important;
        text-decoration: $link-hover-decoration;
    }
}

hr {
    display: block;
    height: 1px;
    border: 0;
    margin: 1em 0;
    padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

