.more-link {
  //display: flex;
  //align-items: center;
  margin-top: 2rem;

  &:after {
    @include font-awesome($fa-var-long-arrow-alt-right);
    margin-left: 8px;
    transform: translateX(0);
    transition: transform .3s ease-out;
  }

  &:hover {
    &:after {
      transform: translateX(5px);
    }
  }
}
