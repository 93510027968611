/* ==========================================================================
    [6. SECTIONS]
========================================================================== */

@import "sections/base";
@import "sections/bubbles";
@import "sections/integration-bubbles";
@import "sections/faqs";
@import "sections/footer";
@import "sections/pricing";
@import "sections/start-free";
@import "sections/why-choose-us";
@import "sections/features-cant-miss";
@import "sections/isometric-mockups";
@import "sections/powered-design";
@import "sections/stay-tunned";
@import "sections/our-proposal";